import { render, staticRenderFns } from "./CapitalLogs.vue?vue&type=template&id=9ca1e630&scoped=true&"
import script from "./CapitalLogs.vue?vue&type=script&lang=ts&"
export * from "./CapitalLogs.vue?vue&type=script&lang=ts&"
import style0 from "./CapitalLogs.vue?vue&type=style&index=0&id=9ca1e630&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ca1e630",
  null
  
)

export default component.exports