























































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class CapitalLogs extends Vue {
  pagerHeader: any = {
    title: "余额日志列表",
    desc: "余额日志列表",
    breadcrumb: ["会员管理", "余额日志列表"]
  };
  loading: boolean = false;
  dialogVisible: boolean = false;
  dialogData:any=null;
  filter: any = {
    memberId: null,
    reasons: null,
    startDate: null,
    endDate: null,
    merchantId: "",
    keywords: "",
    page: 1,
    size: 10,
    sortField: null,
    sortDirection: null
  };
  reasonValues: any = [];
  dateRange: any = [];
  tableData: any = {
    items: [],
    totalCount: 0
  };

      //日期改变   
  timeVoi(value:Date[]){
    if (value != null && value.length == 2) {
        this.filter.startDate=value[0].Format("yyyy-MM-dd hh:mm:ss")
        if(value[1].Format('hh')=="00"&&value[1].Format('mm')=="00"&&value[1].Format('ss')=="00"){
            this.filter.endDate = value[1].Format("yyyy-MM-dd 23:59:59");
            this.dateRange=[this.filter.startDate=value[0].Format("yyyy-MM-dd hh:mm:ss"),this.filter.endDate = value[1].Format("yyyy-MM-dd 23:59:59")]
        }else{
            this.filter.endDate = value[1].Format("yyyy-MM-dd hh:mm:ss")
            this.dateRange=[this.filter.startDate=value[0].Format("yyyy-MM-dd hh:mm:ss"),this.filter.endDate = value[1].Format("yyyy-MM-dd hh:mm:ss")]
        }
      this.readerPage()
    } else {
      this.filter.startDate = "";
      this.filter.endDate = "";
      this.readerPage()
    } 
  }
  // 分页开始
  handleSizeChange(val: number) {
    this.filter.size = val;
    this.readerPage();
  }
  handleCurrentChange(val: number) {
    this.filter.page = val;
    this.readerPage();
  }
  async readerPage() {
    this.loading = true;
    try {
      if (this.reasonValues.length > 0) {
        this.filter.reasons = this.reasonValues;
      } else {
        this.filter.reasons = null;
      }
      let res = await this.$ajax.post(
        "/api/services/app/Member/QueryBalanceChangeLogs",
        {
          ...this.filter
        }
      );

      if (res.data.success) {
        this.tableData = res.data.result;
      }
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  search() {
    this.filter.page = 1;
    // this.filter.keywords = this.keywords;
    this.readerPage();
  }
  activated() {
    if (this.$route.query.id) {
      this.filter.memberId = Number(this.$route.query.id);
      this.readerMemberOptions(this.filter.memberId);
    }
    this.readerPage();
    
  }
  created() {
    this.filter.size = this.systemSettings.pageSize;
    if (this.$route.query.id) {
      this.filter.memberId = Number(this.$route.query.id);
      this.readerMemberOptions(this.filter.memberId);
    }
    this.readerPage();
  }

  merchantItems: any[] = [];
  merchantSearchLoading: boolean = false;
  async readerMerchantOptions(query: string) {
    this.merchantSearchLoading = true;
    try {
      var res = await this.$ajax.get(
        "/api/services/app/Merchant/GetPagedList",
        {
          params: {
            page: 1,
            size: 10,
            keywords: query
          }
        }
      );

      this.merchantItems = res.data.result.items;
    } catch (error) {}
    this.merchantSearchLoading = false;
  }
  get reasonOptions() {
    return this.getEnum("MemberBalanceChangeReason");
  }

  memberSearchLoading: boolean = false;
  memberItems: any[] = [];
  async readerMemberOptions(query: string) {
    this.memberSearchLoading = true;
    try {
      var res = await this.$ajax.post(
        "/api/services/app/Member/QueryPagedList",
        {
          page: 1,
          size: 10,
          keywords: query
        }
      );
      this.memberItems = res.data.result.items;
    } catch (error) {}
    this.memberSearchLoading = false;
  }
    showDetails(index: number, row: any) {
    this.dialogData = row;
    console.log(row);
    
    this.dialogVisible = true;
  }

  async sortChange(e: any) {
    console.log(e);
    if (e.order) {
      this.filter.sortField = e.prop;
      if (e.order == "ascending") {
        this.filter.sortDirection = 0;
      } else {
        this.filter.sortDirection = 1;
      }
    } else {
      this.filter.sortDirection = null;
      this.filter.sortField = null;
    }
    this.readerPage();
  }
}
